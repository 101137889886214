<template>
  <van-tab v-bind="$attrs">
    <div class="tab-content">
      <card :title="detail.title">
        <span class="basic-item">申请数量: {{detail.inspectQuantity}}</span>
        <span class="basic-item">抽检水平: {{ SAMPLING_LEVEL_DICT[detail.samplingLevel] }}</span>
        <span class="basic-item">建议抽检: {{detail.sampleQuantity}}</span>
      </card>
      <card title="检查项">
        <div class="info-item space-between">
          <div class="label">产品类目</div>
          <div class="value">
            {{ detail.productBaseInfo && detail.productBaseInfo.firstGoodsTypeName }}/{{ detail.productBaseInfo && detail.productBaseInfo.secondGoodsTypeName }}/{{ detail.productBaseInfo && detail.productBaseInfo.thirdGoodsTypeName }}
          </div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品品牌</div>
          <div class="value">{{ detail.productBaseInfo && detail.productBaseInfo.brand }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品系列</div>
          <div class="value">{{ detail.productBaseInfo && detail.productBaseInfo.series }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">产品备注</div>
          <div class="value">{{ detail.productBaseInfo && detail.productBaseInfo.remark }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">验货数量</div>
          <div class="value">{{ detail.inspectQuantity }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">抽检水平</div>
          <div class="value">{{ SAMPLING_LEVEL_DICT[detail.samplingLevel] }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">抽检数量</div>
          <div class="value">{{ detail.sampleQuantity }}</div>
        </div>
        <div class="info-item space-between">
          <div class="label">AQL</div>
          <div class="value">CRI {{ detail.aqlCri }} MAJ {{ detail.aqlMaj }} MIN {{ detail.aqlMin }}</div>
        </div>
      </card>
    </div>
    <div class="fixed-btn">
      <van-button type="primary" color="#055C9D" block @click="handleStartClick">开始验货</van-button>
    </div>
  </van-tab>
</template>

<script>
import Card from '@/components/Common/Card.vue'
import {getAction} from '@/api/manage';
import {SAMPLING_LEVEL_DICT} from '@/constants';

export default {
  name: 'CheckInfoTab',
  components: {Card},
  props: {
    id: {
      type: String,
      required: false
    },
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      SAMPLING_LEVEL_DICT
    }
  },
  methods: {
    handleStartClick() {
      this.$router.push({
        name: `taskCheck`,
        query: {
          ...this.$route.query,
          step: this.detail?.status === 'COMPLETED' ? '3' : '1'
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.tab-content {
  height: calc(100vh - 106px - 66px);

  .basic-item {
    margin-right: 8px;
    color: rgba(#000, 0.4);
    font-size: 14px;
  }

  .info-item {
    line-height: 20px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .label {
      width: 80px;
      font-size: 12px;
      color: rgba(#000, 0.4);
    }

    .value {
      width: calc(100% - 80px);
      font-size: 14px;
    }
  }
}
</style>
