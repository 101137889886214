<template>
  <van-tab :title="title" v-bind="$attrs">
    <div class="tab-content">
      <card :title="orderItemDetail.title">
        <span class="basic-item">申请数量: {{orderItemDetail.inspectQuantity}}</span>
        <span class="basic-item">抽检水平: {{ SAMPLING_LEVEL_DICT[orderItemDetail.samplingLevel] }}</span>
        <span class="basic-item">建议抽检: {{orderItemDetail. sampleQuantity}}</span>
      </card>
      <van-collapse v-model="active" class="collapse-box">
        <van-collapse-item v-for="(item1, index1) in checkItemList" :key="index1" :name="index1">
          <div class="check-item check-item-1 vertical-center" slot="title">
            <img :class="{'is-active':  active.includes(index1)}" src="~@/assets/svg/icon-caret.svg" alt=""
                 class="caret-img">
            <div>{{ item1.itemName }}</div>
          </div>
          <van-collapse v-if="item1.children && item1.children.length" v-model="item1.active">
            <van-collapse-item v-for="(item2, index2) in  item1.children" :key="index2" :name="index2">
              <div class="check-item check-item-2 vertical-center" slot="title">
                <img :class="{'is-active':  item1.active && item1.active.includes(index2)}"
                     src="~@/assets/svg/icon-caret.svg" alt="" class="caret-img">
                <div>{{ item2.itemName }}</div>
                <img v-if="item2.checkpointType === CHECKPOINT_CUSTOM" src="~@/assets/svg/icon-delete.svg" alt="" class="del-img"
                     @click.stop="handleItemDelClick(item2)">
              </div>
              <van-collapse v-if="item2.children && item2.children.length && !isHiddenThree(item2)" v-model="item2.active">
                <van-collapse-item v-for="(item3, index3) in  item2.children" :key="index3" :name="index3">
                  <div class="check-item check-item-3 vertical-center" slot="title">
                    <img :class="{'is-active':  item2.active && item2.active.includes(index3)}"
                         src="~@/assets/svg/icon-caret.svg"
                         alt="" class="caret-img">
                    <div>{{ item3.itemName }}</div>
                  </div>
                  <div class="check-detail check-detail-3">{{ item3.children[0].checkDescription }}</div>
                </van-collapse-item>
              </van-collapse>
              <div
                  v-else-if="isHiddenThree(item2)"
                  class="check-detail check-detail-2">
                {{item2.children[0].children[0].checkDescription}}
              </div>
              <div v-else class="check-detail check-detail-2">{{ item2.checkDescription }}</div>
            </van-collapse-item>
          </van-collapse>
        </van-collapse-item>
      </van-collapse>
    </div>
    <div class="fixed-btn">
      <van-button type="primary" color="#055C9D" plain block class="add-btn" @click="togglePopup">新增自定义检查点
      </van-button>
      <van-button :loading="btnLoading" type="primary" color="#055C9D" block @click="handleStartClick">开始验货
      </van-button>
    </div>
    <NewCheckPointPopup :show="showPopup" :single-product-id="id" :product-base-info-id="orderItemDetail.productBaseInfo && orderItemDetail.productBaseInfo.id" @on-close="togglePopup" @on-ok="handlePopupOk"></NewCheckPointPopup>
  </van-tab>
</template>

<script>
import {SAMPLING_LEVEL_DICT, CHECK_TYPE_P_BAR, CHECK_TYPE_P_ITEM, CHECK_TYPE_S_BAR, CHECK_TYPE_S_ITEM, CHECKPOINT_ATTRIBUTE, CHECKPOINT_CUSTOM} from '@/constants';
import Card from '@/components/Common/Card.vue'
import NewCheckPointPopup from "@/components/TaskCheck/NewCheckPointPopup.vue";
import {formatTreeData, formatCheckObj} from '@/utils/util';
import {getAction, deleteAction} from '@/api/manage';

export default {
  name: 'CheckItemsTab',
  components: {Card, NewCheckPointPopup},
  props: {
    title: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    orderItemId: {
      type: String,
      required: true
    },
    orderItemDetail: {
      type: Object,
      default(){
        return {}
      }
    },
  },
  data() {
    return {
      SAMPLING_LEVEL_DICT,
      CHECKPOINT_CUSTOM,
      checkItemList: [],
      active: [],
      showPopup: false,
      btnLoading: false,
    }
  },
  computed: {
    isHiddenThree(){
      return function (item){
        return item.checkpointType === CHECKPOINT_CUSTOM || [CHECK_TYPE_S_BAR, CHECK_TYPE_P_BAR, CHECK_TYPE_S_ITEM].includes(item.checkType)
      }
    },
  },
  created() {
    this.getSingleProductCheck()
  },
  methods: {
    handleStartClick() {
      this.$router.push({
        name: `taskCheck`,
        query: {
          ...this.$route.query,
          step: this.orderItemDetail?.status === 'COMPLETED' ? '3' : '1'
        }
      })
    },
    handleItemDelClick(item) {
      this.Dialog.confirm({
        title: '',
        message: '确认删除自定义检查点？',
      }).then(async () => {
        const res = await deleteAction('/jeecg-system/admin/singleProductCheck/delete', {
          id: item.customCheckpointId
        })
        if(res.success){
          this.getSingleProductCheck()
        }else this.Toast.fail(res.message)
      }).catch(() => {

      });
    },
    togglePopup() {
      this.showPopup = !this.showPopup
    },
    handlePopupOk(form) {
      this.getSingleProductCheck()
      this.showPopup = !this.showPopup
    },
    async getSingleProductCheck(){
      const res = await getAction('/jeecg-system/admin/singleProductInfo/queryCheckById', {
        id: this.id,
        taskOrderItemId: this.orderItemId
      })
      if (res.success) {
        this.checkItemList = formatCheckObj(res.result)
      } else {
        this.Toast.fail(res.message)
      }
    },
  },
}
</script>

<style scoped lang="less">
.tab-content {
  height: calc(100vh - 106px - 134px);

  .basic-item {
    margin-right: 8px;
    color: rgba(#000, 0.4);
    font-size: 14px;
  }

  .collapse-box {
    margin-top: 16px;

    .check-item {
      position: relative;

      .caret-img {
        width: 16px;
        height: 16px;
        margin-left: 8px;

        &.is-active {
          transform: rotate(90deg);
        }
      }

      &.check-item-2 .caret-img {
        margin-left: 32px;
      }

      &.check-item-3 .caret-img {
        margin-left: 60px;
      }

      .del-img {
        width: 16px;
        height: 16px;
        position: absolute;
        right: 16px;
      }
    }

    .check-detail {
      white-space: pre-line;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.90);
      text-align: left;
      line-height: 22px;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-right: 8px;

      &.check-detail-1 {
        padding-left: 64px;
      }

      &.check-detail-2 {
        padding-left: 80px;
      }

      &.check-detail-3 {
        padding-left: 96px;
      }
    }
  }

  ::v-deep {
    .van-collapse-item__content {
      padding: 0;
    }

    .van-cell {
      padding: 10px 0;
      border-bottom: 1px solid #DFE4EB;
    }

    .van-cell::after, .van-cell__right-icon {
      display: none;
    }
  }

}

.fixed-btn {
  .add-btn {
    margin-bottom: 22px;
  }
}
</style>
